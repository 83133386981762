import firebase from 'firebase/app';
import 'firebase/messaging';
const firebaseCloudMessaging = {
  //checking whether token is available in indexed DB
  tokenInlocalforage: async () => {
    return localStorage.getItem('fcm_token');
  },
  //initializing firebase app
  init: async function () {
    if (!firebase.messaging.isSupported()) return;
    Notification.requestPermission();
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: 'AIzaSyA9jXUIHzhQxmp7yl_CEMyb1fYO_lV-KWI',
        authDomain: 'proud-storm-267016.firebaseapp.com',
        databaseURL: 'https://proud-storm-267016.firebaseio.com',
        projectId: 'proud-storm-267016',
        storageBucket: 'proud-storm-267016.appspot.com',
        messagingSenderId: '103645675899',
        appId: '1:103645675899:web:9bab00a6e3dcf4b86d3dde',
        measurementId: 'G-NMLXLWL9L7',
      });
      try {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then(function (registration) {
              console.log('Service Worker Registered');
              firebase.messaging().useServiceWorker(registration);
            });
        }

        const messaging = firebase.messaging();
        const tokenInLocalForage = await this.tokenInlocalforage();
        //if FCM token is already there just return the token
        if (tokenInLocalForage !== null) {
          return tokenInLocalForage;
        }
        //requesting notification permission from browser
        const status = await Notification.requestPermission();
        if (status && status === 'granted') {
          //getting token from FCM
          const fcm_token = await messaging.getToken();
          if (fcm_token) {
            //setting FCM token in indexed db using localforage
            localStorage.setItem('fcm_token', fcm_token);
            console.log('fcm token', fcm_token);
            //return the FCM token after saving it
            return fcm_token;
          }
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  },
};
export { firebaseCloudMessaging };
