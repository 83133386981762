import { $ } from "@/graphql/graphql-zeus";
import { firebaseCloudMessaging } from "@/utils/firebase-messaging";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";

export const useLoggedIn = (redirect?: string) => {
  const router = useRouter();
  const { data, loading, called, refetch } = useTypedQuery(
    {
      me: {
        "...on RestaurantAdmin": {
          id: true,
          name: true,
        },
        "...on Customer": {
          id: true,
        },
        "...on Rider": {
          id: true,
        },
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const [setFcmToken] = useTypedMutation({
    setFirebaseCloudMessagingToken: [
      {
        token: $`token`,
      },
      true,
    ],
  });

  useEffect(() => {
    firebaseCloudMessaging.tokenInlocalforage().then((t) => {
      if (t)
        setFcmToken({
          variables: {
            token: t,
          },
        });
    });

    if (called && !data && !loading) {
      router.push("/");
    }

    if (data?.me && "name" in data.me && redirect) {
      router.push(redirect);
    }
  }, [data, loading, called, setFcmToken]);

  return { loading, refetch };
};
