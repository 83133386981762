import { useLoggedIn } from "@/hooks/useLoggedIn";
import { Button, Form, Input, message } from "antd";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { media, style } from "typestyle";
import { $, UserType } from "../graphql/graphql-zeus";
import { useTypedMutation } from "../graphql/hooks";

export function Login() {
  const history = useRouter();
  const [forgotPassword, setForgotPassword] = useState(false);
  const params = useRouter().query;

  const { loading: accountLoading, refetch } = useLoggedIn(
    typeof params.from === "string" ? params.from : "/dash"
  );

  const [loginMutation, { loading }] = useTypedMutation(
    {
      login: [
        {
          login: {
            email: $`email`,
            password: $`password`,
            userType: "restaurant",
          },
        },
        {
          user: {
            id: true,
            name: true,
            email: true,
            roles: true,
          },
        },
      ],
    },
    {
      onCompleted: () => {
        refetch();
        message.success("Welcome back!");
      },
    }
  );

  const [resetMutation, { loading: resetting }] = useTypedMutation(
    {
      resetPasswordRequest: [
        {
          type: UserType.restaurant,
          email: $`email`,
        },
        true,
      ],
    },
    {
      onCompleted() {
        message.success("Password reset sent!");
        setForgotPassword(false);
      },
    }
  );

  const doReset = async ({ email }) => {
    await resetMutation({
      variables: {
        email,
        type: "restaurant",
      },
    });
  };

  const doLogin = async ({ email, password }) => {
    await loginMutation({
      variables: {
        email,
        password,
      },
    });
  };

  const isZesh =
    typeof window !== "undefined" && window.location.host.includes("zesh");

  return (
    <div>
      <div style={{ position: "absolute", top: 8, right: 64 }}>
        <Button
          href="https://tripetto.app/run/REU5MSGZBX"
          type="primary"
          {...{ target: "_blank", rel: "noopener noreferrer" }}
          style={{ marginTop: 8, flex: 1 }}
        >
          Become a Partner
        </Button>
      </div>
      <div
        className={styles.background}
        style={
          isZesh
            ? {
                background: "linear-gradient(45deg, #13d7fe, #fd06d4)",
              }
            : undefined
        }
      >
        <div className={styles.food}>
          <img style={{ maxWidth: "100%" }} src={"/assets/food.svg"} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            justifyContent: "center",
          }}
        >
          <div style={{ marginBottom: 8 }}>
            <img
              src={`/assets/logo${isZesh ? "-zesh" : ""}.png`}
              style={{ maxHeight: "48px" }}
            />
          </div>
          <div
            style={{
              background: "white",
              borderRadius: 8,
              padding: 32,
              maxWidth: 500,
            }}
          >
            <Form onFinish={(e) => (forgotPassword ? doReset(e) : doLogin(e))}>
              <Form.Item name="email">
                <Input placeholder="Email" />
              </Form.Item>

              {!forgotPassword && (
                <Form.Item name="password">
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  htmlType="submit"
                  loading={loading || resetting || accountLoading}
                  style={{ flex: 1 }}
                >
                  <span>
                    {forgotPassword
                      ? resetting
                        ? "Requesting reset..."
                        : "Reset Password"
                      : loading
                      ? "Logging in..."
                      : "Login"}
                  </span>
                </Button>

                <Button
                  type="dashed"
                  style={{ marginLeft: 16, cursor: "pointer", flex: 1 }}
                  onClick={() => setForgotPassword(!forgotPassword)}
                >
                  {forgotPassword
                    ? "Back to login"
                    : "Forgotten your password?"}
                </Button>
              </div>

              <div style={{ display: "flex" }}></div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

const styles = {
  background: style(
    {
      display: "flex",
      height: "100vh",
      flexDirection: "row",
      background:
        "linear-gradient(45deg, #31b668 0%, #31b668 45%, transparent 26%)",
      padding: "0 64px",
    },
    media(
      { maxWidth: "960px" },
      {
        background:
          "linear-gradient(20deg, #31b668 0%, #31b668 25%, transparent 20%)",
      }
    )
  ),
  food: style(
    {
      flex: 3,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    media(
      { maxWidth: "960px" },
      {
        display: "none",
      }
    )
  ),
};
